@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');
:root {
	--width: 300px;
	--padding: 10px;
	--background-color: rgb(123, 120, 120);
	--width-collapsed: 50px;
	--border-botton-color: solid 1px rgb(158, 145, 145);
}
* {
	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 900;
}
.text-white {
	color: white;
}
.text-blue {
	color: rgb(90, 90, 225);
}
.text-grey {
	color: rgb(108, 105, 105);
}
.cursor-pointer {
	cursor: pointer;
}
.text-red {
	color: red;
}
.mainDashboardContainer {
	display: flex;
	margin-left: var(--width-collapsed);
	justify-content: center;
	text-align: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(#95d78c, #5d6181);
}
.mainDashboardContainer-expanded {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	min-height: 100vh;
	background: linear-gradient(#95d78c, #5d6181);
}
.mainDashboardContainer-loggedOut {
	margin-left: unset;
}
.containerHomePages {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 450px;
	padding: 4%;
	align-items: center;
}
.customSelectHomePage {
	width: 95%;
	height: 60px;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	padding: 2%;
	background-color: #f9ccccb3;
	margin: 2%;
}
.selectLabelText {
	margin: 2%;
}
.customSelect {
	width: 100%;
	max-width: 450px;
	padding: 1%;
	text-align: center;
	border-radius: 15px;
}

.containerCustomButtons {
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	width: 100%;
	max-width: 450px;
	padding: 4%;
}
.containerIndividualButton {
	padding: 4%;
	margin: 2%;
	border-radius: 15px;
	min-height: 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: linear-gradient(#a0bedd, #5d6181);
}

.containerIndividualButtonDisabled {
	background: #ac9c9c !important;
}

.numberIndividual {
	margin: 0 2%;
	border-right: solid 2px black;
	width: 10%;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100%;
	color: black;
}
.textIndividual {
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 0 2%;
}

.boxShadow {
	box-shadow: 0px 2px 10px 5px rgba(144, 143, 143, 0.5) !important;
}
.badge {
	padding: 1%;
	border-radius: 5px;
}
.badge-success {
	background-color: #50d47c;
}
.badge-disabled {
	background-color: #959996;
}

/*START Custom Modal */
.modal--container {
	box-sizing: border-box;
	display: flex;
	background-color: transparent;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	padding: 5% 10%;
}

.modal--body {
	background-color: #f1f1f1;
	width: 600px;
	padding: 30px;
	max-width: 100%;
	border-radius: 7px;
	box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
	height: fit-content;
}
.modal--container--input-image {
	display: flex;
	padding: 2%;
	flex-direction: column;
	margin-bottom: 5%;
}
.modal--inputs {
	display: flex;
	justify-content: space-between;
}
.modal--inputs--quantity {
	justify-content: center;
	align-items: center;
}
input {
	border: none;
	background-color: #f1f1f1;
	border-bottom: 1px solid gray;
	width: 90%;
	margin: 0 5%;

	height: 100%;
}
input:hover {
	border: none;
}
.modal--label {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.input-image {
	max-width: 30px;
}
.modal--inputs--container--image {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	max-width: 10%;
	width: 100%;
}
.modal--inputs--container--input {
	max-width: 90%;
	width: 100%;
	height: 30px;
}

.modal--header {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	border-bottom: 1px solid #9198e5;
	font-size: 1.5rem;
	margin-bottom: 5%;
	padding-bottom: 5%;
}

.modal--footer {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	border-top: 1px solid #9198e5;
}

.modal--footer--container--button {
	height: 100%;
	max-height: 40px;
	width: 100%;
	display: flex;
	margin-top: 5%;
	justify-content: center;
}
.modal-search-container--button {
	border-radius: 15px;
	padding: 3%;
	display: flex;
	border: none;
	justify-content: center;
	align-items: center;
	width: 95%;
	height: 20px;
	margin: 2% 0;
	box-sizing: border-box;
}
.modal--footer-button {
	border-radius: 15px;
	padding: 10%;
	display: flex;
	border: none;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.button-search {
	border-radius: 15px;
	padding: 2%;
	display: flex;
	border: none;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 95%;
}
.button--icon {
	margin-right: 7%;
}
.modal--footer-button--close {
	background-color: #ca7474;
}
.modal--footer-button--close:hover {
	box-shadow: 0px 2px 10px 5px #f7c2c2 !important;
}
.modal--footer-button--save {
	background-color: #50d47c;
}
.modal--footer-button--search {
	background-color: #546da3;
}
.modal--footer-button--save:hover {
	box-shadow: 0px 2px 10px 5px #a3e5b9 !important;
}
/*END Custom Modal */
/* START Link */

.text-decoration {
	text-decoration: none;
}

/* END link */

/* START preceeding */

.preceeding--form {
	background-color: #f1f1f1;
	height: 100vh;
	width: 100%;
}
.containerPreceeding {
	display: flex;
	max-height: 100vh;
	box-sizing: border-box;
}
.preceeding--header {
	background-color: #3a3d57;
	height: 27px;
	display: flex;
	padding: 10px 20px;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.preeceding--link {
	/* 	width: 100%; */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-right: 5%;
}
/* END preceeding */
.photo--container {
	display: flex;
	flex-direction: column;
	margin-top: 2%;
	width: 100%;
	align-items: center;
	/* 	height: auto; */
}
.video--photo--container {
	width: 100%;
	height: auto;
	box-sizing: border-box;
}
.video--photo {
	border-radius: 15px;
	width: 160px;
	height: 200px;
}

/* STAR photo */

/* START login form */
.login--form--container {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	background-color: #f1f1f1;
	width: 350px;
	height: 300px;
	border-radius: 15px;
	opacity: 0.8;
	padding: 2%;
}
.login--form--container__input {
	width: 100%;
	padding: 5% 2%;
}
.login--form--container__button_container {
	width: 250px;
}
.text-center {
	text-align: center;
}
.login--form--container__input__input {
	height: 40px;
}
input:focus {
	outline: none;
	border: 1px solid rgb(95, 209, 122);
	border-radius: 15px;
}
.login--form--container__button {
	width: 100%;
	margin: 10% 0;
	border-radius: 15px;
	height: 40px;
}
.login--form--container__button:hover {
	background: linear-gradient(45deg, rgb(234, 149, 149), rgba(8, 0, 6, 0.291));
	color: #f1f1f1;
}
.login--form--container__button__text {
	font-size: 1.1rem;
}

input[type='number'] {
	-moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.login--links {
	margin: 2% 0 2%;
}
@media (max-width: 600px) {
	.login--form--container {
		padding: 2% 2%;
		margin: 2% 2%;
		height: 400px;
	}
}
/* START SIDEBAR */

.sidebar-menu-container {
	background-color: #ffffff;
	color: white;
	position: fixed;
	/* height: 100%; */
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	min-height: 100vh;
}
.sidebar-menu-link {
	text-decoration: none;
}
/* header */
.sidebar-menu-header {
	box-sizing: border-box;
	border-bottom: var(--border-botton-color);
	background-color: #3a3d57;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.sidebar-menu--button-icons {
	vertical-align: middle;
}
.sidebar-menu--button-icons {
	display: inline-block;
	cursor: pointer;
	padding: 10px;
	box-sizing: border-box;
	width: var(--width-collapsed);
}
.sidebar-menu--button-icons .sidebar-menu--button-icon--hamburger {
	background-color: #f1f1f1;
	width: 100%;
	height: 2px;
	margin: 5px 0;
}

/* profile */
.sidebar-menu--descriptions {
	background-color: #3a3d57;
	border-bottom: var(--border-botton-color);
	padding: var(--padding) 0;
	text-align: center;
}
.sidebar-menu--descriptions-loggedIn {
	background-color: #12b20c;
}
.sidebar-menu--logo img {
	box-sizing: border-box;
	padding: var(--padding);
	margin: 0 auto;
	border-radius: 15px;
	width: var(--width);
	max-width: 150px;
	max-height: 150px;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.sidebar-menu--name {
	font-size: 24px;
	padding: var(--padding) 0;
	overflow: hidden;
	margin: 5px;
}

/* Links */
.sidebar-menu--items-container {
	overflow: hidden;
	width: 100%;
	display: flex;
	text-align: left;
	margin-left: 10%;
	flex-direction: column;
}
.sidebar-menu--item {
	width: var(--width);
	padding: 5% 5%;
}
.sidebar-menu--separator {
	height: 1px;
	border-bottom: var(--border-botton-color);
	margin: 1% 0;
	width: 80%;
}

/* menu expanded */
.sidebar-menu-expanded {
	display: inline-block;
	width: calc(100% - 50px);
	margin-right: -5px;
	padding: var(--padding);
}

/* menu collapsed */
.sidebar-menu-collalsed {
	width: var(--width-collapsed);
}
.sidebar-menu-collalsed .sidebar-menu--title {
	display: none;
}

.sidebar-menu-collalsed .sidebar-menu--logo img {
	width: 100%;
}
.sidebar-menu-collalsed .sidebar-menu--name {
	display: none;
}
.sidebar-menu-collalsed .sidebar-menu-link--name {
	display: none;
}
.sidebar-menu-collalsed .sidebar-menu-link--icon {
	width: var(--width-collapsed);
}
.sidebar-menu-collalsed .sidebar-menu--items-container {
	align-items: center;
	justify-content: center;
	text-align: center;
}
.sidebar-menu-collalsed .sidebar-menu--item {
	padding: 30% 5%;
}

.sidebar-menu-collalsed .sidebar-menu-header .sidebar-menu--title {
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
}

.sidebar-menu-download-button-container {
	display: flex;
	justify-content: center;
	max-width: var(--width);
	height: 50px;
}
.sidebar-menu-download-button {
	border-radius: 15px;
}

/* END SIDEBAR */

/* START LISTS  */

.lists-containers-card {
	width: 90%;
	background-color: #f1f1f1;
	border-radius: 15px;
	min-height: 50vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2%;
}
.lists-container--header {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 90%;
}
.lists-container--button--header {
	display: flex;
	text-align: center;
	align-items: center;
	width: 50%;
	margin: 0;
}
.lists-container--button--header:nth-child(1) {
	width: 35%;
	margin: 0;
}

.lists-button {
	padding: 10px;
	border-radius: 15px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	align-items: center;
	border: solid 1px gray;
}
.lists-button:hover {
	background-color: #50d47c;
}
.lists--header {
	font-size: 2rem;
	padding: 1% 0;
}

.lists-container--table {
	display: flex;
	justify-content: center;
	width: 100%;
	overflow-x: auto;
	margin-top: 2%;
}
.lists--table {
	width: 90%;
}
.table--separator {
	height: 1px;
	border-bottom: var(--border-botton-color);
	width: 100%;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid #ddd;
}
th {
	border-bottom: solid 1px black;
}
/* th,
td {
	padding: 8px;
} */
tr:nth-child(even) {
	background-color: #e3dfdf;
}
.table-buttons-container {
	display: flex;
	justify-content: center;
}
.table-custom-icons {
	padding: 1% 1%;
	margin: 1%;
}
@media (max-width: 600px) {
	th,
	td {
		padding: 8px;
	}
}

.modal--container--users {
	box-sizing: border-box;
	display: flex;
	background-color: transparent;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	padding: 5% 10%;
}
.modal--body--users {
	background-color: #f1f1f1;
	width: 1020px;
	padding: 30px;
	max-width: 100%;
	border-radius: 7px;
	box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
	height: fit-content;
}
.modal-containers--inputs {
	display: flex;
	flex-direction: row;
	padding: 2%;
}
.modal-container-input {
	width: 50%;
}
.modal-container-select {
	width: 50%;
}
.modal-select {
	background: transparent;
	border: none;
	font-size: 14px;
	height: 30px;
	padding: 5px;
	width: 90%;
}

.modal--container--users .modal--footer-button {
	padding: 5%;
}

.modal--container--users .modal--footer {
	border-top: none;
}
.modal--container--users .modal--header {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}
.modal-users-separator {
	height: 1px;
	border-bottom: var(--border-botton-color);
	margin: 1% 0;
	padding: 1% 0;
	width: 100%;
}
select:focus {
	outline: none;
}

.button-icon-FontAwesomeIcon {
	padding: 5px;
	margin: 10%;
	border-radius: 10px;
	background-color: transparent;
	border: solid 1px gray;
}

.button-icon-FontAwesomeIcon-info:hover {
	background-color: #92abea;
}
.button-icon-FontAwesomeIcon-success:hover {
	background-color: #50d47c;
}
.button-icon-FontAwesomeIcon-danger:hover {
	background-color: #e8877c;
}
.button-icon-FontAwesomeIcon-warning:hover {
	background-color: #e7db7f;
}
.icon-FontAwesomeIcon {
	padding: 5px;
}

/* end LISTS CONTAINERS*/
/* START modal deitals data */
.modal--container--details-data {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	flex-direction: column;
}
.modal--container--details-input {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 1%;
}
.modal--details-input {
	display: flex;
	text-align: center;
	padding-top: 5%;
}
/* END modal deitals data */

.general--container-card {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	background-color: #f1f1f1;
	width: 50%;
	height: 100%;
	min-height: 300px;
	border-radius: 15px;
	opacity: 0.8;
	padding: 4%;
}

@media (max-width: 600px) {
	.general--container-card {
		width: 100%;
		margin: 5%;
	}
}

/* START user profile*/
.container--profile-header {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}

.container--profile-name {
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	height: 50px;
	width: 100%;
}

.profile-container-info {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.profile-container-colums {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.profile--container-inputs {
	display: flex;
	flex-direction: column;
	margin: 1% 0;
	align-items: center;
}
.profile--container-icons {
	display: flex;
	align-items: center;
}

.profile-input {
	text-align: center;
	padding-top: 2%;
	font-size: 1rem;
}
.profile-header {
	font-size: 2rem;
	font-style: oblique;
}
.profile-name {
	font-size: 1.5rem;
}
.profile-input-label {
	font-size: 1.3rem;
}
.profile-tables {
	color: rgb(47, 162, 175);
}
.profile-tables:hover {
	color: rgb(114, 193, 203);
}
.no-border {
	border: none;
}
@media (max-width: 600px) {
	.profile-container-info {
		flex-direction: column;
		width: 100%;
	}
	.profile-container-colums {
		width: 100%;
	}
	.profile-name {
		font-size: 1.2rem;
	}
	.modal--footer--container--button-profile {
		padding: 4%;
	}
}
@media (min-width: 600px) {
	.modal--footer-button-profile {
		padding: 4%;
	}
}

/* END user profile*/

.boxShadow {
	box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
}

/*START Footer */

.footer-container {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: rgb(174, 169, 169);
	color: white;
	text-align: right;
	padding: 1%;
	opacity: 0.8;
}
.footer--container-text {
	padding: 0 4%;
}
.footer-color {
	background: rgb(69, 80, 84);
	background: linear-gradient(
		130deg,
		rgba(57, 59, 73, 0.98) 0%,
		rgba(49, 53, 129, 0.95) 100%
	);
}

/* END footer */
/* start General Card */
.borderRadius {
	border-radius: 15px;
}
.card {
	width: 90%;
	background-color: #f1f1f1;
	border-radius: 15px;
	min-height: 50vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2%;
	opacity: 0.9;
}
.card--container-title {
	display: flex;
	justify-content: center;
}
.card-title {
	padding: 1% 0;
	margin: 0;
}
.titleText {
	font-size: 1.6rem;
}

.displayNone {
	display: none;
}

/* END general card */

/* START LOADING */
.rootLoading {
	text-align: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1051;
	background-color: rgba(0, 0, 0, 0.42);
	overflow: none;
}

.containerLoading {
	height: 100px;
	width: 100px;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50px;
}

.loaderLoading {
	border: 8px solid #f6f6f6;
	border-radius: 50%;
	border-top: 8px solid #343875;
	width: 80px;
	height: 80px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* ENDLOADING */

.displayNone {
	display: none;
}
.displayBlock {
	display: inline-block;
}

/* Preceeding buttons */
.container--preceeding-info {
	margin: 5%;
	box-sizing: border-box;
	text-align: justify;
	border: solid 2px black;
	padding: 5%;
}
.container-preceeding-buttons {
	max-width: 100%;
	display: flex;
	justify-content: center;
}
.preceeding-open-button {
	border-radius: 15px;
	padding: 10%;
	display: flex;
	justify-content: center;
	margin-top: 10%;
	min-width: 150px;
}

.preceeding-open-button--open {
	background-color: #40ba77;
}
.preceeding-open-button--open:hover {
	box-shadow: 0px 2px 10px 5px #669c88 !important;
}

.container-cam-buttons {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	margin: 1% 0;
}
.cam-buttons-take-photo-and-close {
	display: flex;
	width: 414px;
	flex-direction: row;
	justify-content: space-around;
}
.cam-common-button {
	border-radius: 15px;
	padding: 10%;
	display: flex;
	justify-content: center;
	min-width: 150px;
}

.cam-take-photo-button--open {
	background-color: #0cc45f;
}
.cam-take-photo-button--open:hover {
	box-shadow: 0px 2px 10px 5px #646665 !important;
}
.container-general-cam-sending-button {
	display: flex;
	justify-content: center;
	width: 100%;
}
.container-cam-sending-button {
	width: 414px;
	display: flex;
	justify-content: center;
}
.cam-sending-button {
	border-radius: 15px;
	padding: 5%;
	display: flex;
	justify-content: center;
	width: 100%;
}

.cam-sending-button--bg {
	background-color: rgb(80, 212, 124);
}
.cam-sending-button--bg:hover {
	box-shadow: 0px 2px 10px 5px #7a79a1 !important;
}

.upload-photo--container {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: center;
	height: 75vh;
}

.photo-upload--container {
	width: 50%;
}
.input-file-container {
	text-align: center;
	padding: 3%;
	border: dotted black;
}
.input-file-tag {
	display: none;
}
.label-input-file-tag {
	cursor: pointer;
}
.common-container-upload-buttons {
	display: flex;
	width: 90%;
	flex-direction: row;
	margin: 2%;
	justify-content: center;
}
.common-button-upload-container {
	width: 400px;
	display: flex;
	justify-content: center;
	margin: 0 5px;
}
.common-button-upload {
	border-radius: 15px;
	padding: 5%;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 40px;
	justify-content: center;
	text-align: center;
	align-items: center;
}
.common-button-cancel {
	background-color: #ca7474;
}
.common-button-cancel:hover {
	box-shadow: 0px 2px 10px 5px #f7c2c2 !important;
}

.common-button-send {
	background-color: #50d47c;
}

.common-button-send:hover {
	box-shadow: 0px 2px 10px 5px #a3e5b9 !important;
}
.photo-uload-bottom-container {
	display: flex;
	flex-direction: column;
	bottom: 0;
	width: 100%;
	align-items: center;
	margin-top: 10px;
}

@media (max-width: 600px) {
	.photo-upload--container {
		width: 90%;
		margin: 0 5% 5%;
	}
}

@media (min-width: 600px) {
	.container--preceeding-info {
		padding: 1%;
		width: 50%;
	}
}

/* START RADIO BUTTONS */

.radio-item {
	display: inline-block;
	position: relative;
	padding: 0 6px;
	margin: 10px 10px;
}

.radio-item input[type='radio'] {
	display: none;
}

.radio-item label {
	color: #666;
}

.radio-item label:before {
	content: ' ';
	display: inline-block;
	position: relative;
	top: 5px;
	margin: 0 5px 0 0;
	width: 20px;
	height: 20px;
	border-radius: 11px;
	border: 2px solid #004c97;
	background-color: transparent;
}

.radio-item input[type='radio']:checked + label:after {
	border-radius: 11px;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 9px;
	left: 10px;
	content: ' ';
	display: block;
	background: #004c97;
}

.container--paginator {
	display: flex;
	margin: 10px 0px;
}

.container--formFieldError{
	margin: 5px 0;
}